import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';

const Services = () => {
  const {t} = useTranslation();

  return (
    <>
      <div id="services" className="py-8 bg-bgColor-600">
        <div className="container grid grid-cols-1 px-8 mx-auto lg:space-x-8 lg:grid-cols-2">
          <div className='max-w-lg pb-8 mx-auto lg:pb-0'>
            <h2 className='max-w-xs pb-4 text-4xl font-bold leading-10 md:text-5xl font-NewYork'>{t('services_title')}</h2>
            <p className='max-w-lg mb-8 text-sm leading-7 lg:text-lg font-Montserrat'>{t('services_subtitle')}</p>
            <div>
              {/* <div className="text-sm leading-10 text-black lg:text-lg font-NewYork opacity-60">Dr. David Sampayo</div> */}
              <StaticImage src="../../images/sampayo.png" alt="Doctor David Sampayo" />
            </div>
          </div>
          <div className='max-w-lg mx-auto'>
            <div>
              <div className='pb-4'>
                <div className="font-Montserrat text-sm lg:text-lg leading-10 uppercase text-[#915F20] tracking-widest">
                  Unidad Medicina Estética
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Líneas de expresión</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Tratamiento de labios</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Sonrisa gingival</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Hilos tensores</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Marcación mandibular</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Armonización facial</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Ojeras</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Redensificación de la piel</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Estimulación de colágeno</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Bruxismo</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Neuromoduladores</p>
                </div>
              </div>

              <div className='pb-4'>
                <div className="font-Montserrat text-sm lg:text-lg leading-10 uppercase text-[#915F20] tracking-widest">
                  Unidad de cuidado de la piel
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Valoración del estado de la piel</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Rutina facial personalizada</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Peeling químico</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Skiinbooster</p>
                </div>
              </div>

              <div className='pb-4'>
                <div className="font-Montserrat text-sm lg:text-lg leading-10 uppercase text-[#915F20] tracking-widest">
                  Unidad capilar
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Valoración</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Mesoterapias</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Redensificadores</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>Injertos</p>
                </div>
                <div className='border-b border-[#CFC4B9] border-opacity-20'>
                  <p className='pb-2 text-sm font-bold leading-7 lg:text-lg font-Montserrat'>PRP</p>
                </div>
              </div>

              {/*
              <div>
                <div className="font-Montserrat text-sm lg:text-lg leading-10 uppercase text-[#915F20] tracking-widest">
                  {t('no_surgery')}
                </div>
                <div className='mb-4 border-b border-[#CFC4B9] border-opacity-20'>
                  <Collapsible trigger={t('botox')}>
                    <p className='pb-4 text-sm font-normal leading-7 lg:text-lg font-Montserrat'>{t('botox_text')}</p>
                  </Collapsible>
                </div>
              </div> */}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;