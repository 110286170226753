import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../components/structure/layout.js'
import Seo from '../components/essentials/seo.js'
import Hero from '../components/landing/hero.js'
import Services from '../components/landing/services.js'
import Contact from '../components/landing/contact.js'
import GoogleReviews from '../components/landing/google_review.js'
import Blog from '../components/landing/blog_ghost.js'

const Home = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Seo title={t('title')} />
      
      <Hero/>

      <div className="">
        <Services/>
        <Contact/>
        <Blog/>
        <GoogleReviews/>
      </div>

    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index", "landing"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;