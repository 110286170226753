import React from 'react'
import { StaticQuery, graphql } from "gatsby";
import PostCard from '../blog/post_card';

const BlogGhost = () => {
  return (
    <StaticQuery
      query={graphql`
        query GhostPostQuery {
          allGhostPost(
              sort: { order: DESC, fields: [published_at] }
              limit: 3
          ) {
              edges {
                  node {
                      ...GhostPostFields
                  }
              }
          }
        }
      `}
      render={data => (
        <div id="blog" className="px-16 pt-8 pb-4 bg-bgColor-600">
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Blog</h2>
            </div>
            <div className="grid max-w-lg gap-5 mx-auto mt-8 lg:grid-cols-3 lg:max-w-none">
              {data.allGhostPost.edges.map(({ node }) => (
                  <PostCard key={node.id} post={node} />
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default BlogGhost;