import * as React from "react";
import PropTypes from "prop-types";

const PostCard = ({ post }) => {
    const url = `https://blog.profile.health/${post.slug}/`;

    return (
        <a href={url} target="_blank" rel="noreferrer">
            <div className="flex flex-col overflow-hidden rounded-t-lg">
                <div className="flex-shrink-0">
                    <img className="object-cover w-full h-48" src={post.feature_image} alt={post.title} />
                </div>
            </div>

            <div className="flex flex-col justify-between flex-1 p-6 bg-white rounded-b-lg">
                <div className="flex-1">
                <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                <p className="mt-3 text-base text-gray-500">{post.excerpt}</p>
                </div>
            </div>
        </a>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PostCard;
