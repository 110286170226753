import React from 'react'
import { Script } from "gatsby"

const GoogleReview = () => {

  return (
    <>
      <div id="google" className="px-16 py-8 text-center bg-bgColor-600">
        <Script src="https://apps.elfsight.com/p/platform.js" defer/>
        <div class="elfsight-app-df4ec67a-a3cf-49bf-ae02-8d177e39e4f4"></div>
      </div>
    </>
  );
};

export default GoogleReview;