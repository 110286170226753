import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
  const {t} = useTranslation();

  return (
    <div id="hero" className="text-center text-white bg-center bg-no-repeat bg-cover rounded-br-profileHero" style={{ 
      // backgroundImage: `url("https://s3.eu-west-3.amazonaws.com/100hawks.com/main.jpg")`
      backgroundImage: `url("https://s3.eu-west-3.amazonaws.com/100hawks.com/mobile_profile.jpg")`
    }}>
      <div className='max-w-md px-8 mx-auto mb-16 pt-44 lg:pt-64 md:px-0'>
        <StaticImage src="../../images/logo.svg" alt="The Profile Clinic logo" />
      </div>
      <div className='max-w-md px-2 mx-auto md:px-0'>
        {/* <h2 className='mb-24 text-2xl uppercase'>{t('hero_subtitle')}</h2> */}
        <AnchorLink to="#contact" title="Book">
          <button id="hero_button" className='px-8 py-4 mb-16 text-lg font-bold text-white rounded-full md:px-16 hover:bg-gray-600 font-NewYork bg-buttonColor-600 drop-shadow-profile'>{t('hero_cta')}</button>
        </AnchorLink>
        {/* <p className='w-1/2 pb-48 mx-auto text-sm uppercase font-NewYork opacity-70'>{t('hero_free')}</p> */}
      </div>
    </div>
  );
};

export default Hero;